import {
  CurrencyDto,
  GrantAppDto,
  GrantAppDtoStatusEnum,
  SearchHighlightDto,
  TimeZoneDto,
  UserDto,
  UserPermissionDto,
} from "@/sg_copy/swagger-generated";
import moment from "moment";
import "moment-timezone";
import {NotificationModel} from "@/sg_copy/store/NotificationStore";
import {currencyFilter} from "@/shared/CurrencyFilter";

const DATE_FORMAT = "h:mma, DD MMM YYYY";
const DEFAULT_TIME_ZONE_ID = "Australia/Sydney";

export default class GrantApp {
  public user: UserDto;
  public processing = false;
  public errorMessage: NotificationModel;
  public app: GrantAppDto;
  private visible = false;
  private removed = false;
  private expanded = false;

  constructor(app: GrantAppDto, user: UserDto) {
    this.app = app;
    this.user = user;
  }

  public get isSubmitted(): boolean {
    return this.app.status === GrantAppDtoStatusEnum.SUBMITTED;
  }

  public get statusDate(): string {
    if (this.isSubmitted) {
      if (this.app.subsequentAppStatus) {
        if (this.app.subsequentAppStatus.respStatus) {
          if (this.app.subsequentAppStatus.respStatus === "SUBMITTED") {
            return (
              "Submitted " +
              (this.app.subsequentAppStatus.respSubmitDate
                ? moment(this.app.subsequentAppStatus.respSubmitDate)
                    .tz(this.timeZoneId)
                    .format(DATE_FORMAT)
                : "")
            );
          } else if (this.app.subsequentAppStatus.respStatus === "INCOMPLETE") {
            if (this.app.subsequentAppStatus.respDueDate) {
              return (
                "<b " +
                (this.isOverdueOrIsOutsideVariationStage
                  ? "style='color:lightgrey'"
                  : "") +
                ">Due " +
                this.formattedDate(this.app.subsequentAppStatus.respDueDate) +
                "</b>"
              );
            } else if (this.outsideVariationStage) {
              return "";
            }
          } else if (
            this.app.subsequentAppStatus.respStatus === "IN_PROGRESS" ||
            this.app.subsequentAppStatus.respStatus === "NOT_STARTED"
          ) {
            if (this.app.subsequentAppStatus.respDueDate) {
              return (
                "<b " +
                (this.dueInaWeek ? "style='color:rgb(200,0,0)'" : "") +
                ">Next form due " +
                this.formattedDate(this.app.subsequentAppStatus.respDueDate) +
                "</b>"
              );
            } else {
              return "<i style='color:lightgrey'>No due date</i>";
            }
          }
        }
      }
      return (
        "Submitted " +
        (this.app.submitDate ? this.formattedDate(this.app.submitDate) : "")
      );
    } else if (this.app.dueDate) {
      return (
        "<b " +
        (this.overdue(this.app.dueDate) ? "style='color:lightgrey'" : "") +
        ">Due " +
        this.formattedDate(this.app.dueDate) +
        "</b>"
      );
    } else {
      return "<i style='color:lightgrey'>No due date</i>";
    }
  }

  public overdue(date?: string): boolean {
    return moment(date)
      .tz(this.timeZoneId)
      .isSameOrBefore(moment().tz(this.timeZoneId).subtract(1, "seconds"));
  }

  public get dueInaWeek(): boolean {
    return (
      this.app.subsequentAppStatus &&
      moment(this.app.subsequentAppStatus.respDueDate)
        .tz(this.timeZoneId)
        .diff(moment(), "days") < 7
    );
  }

  public formattedDateWithTimeZone(date: string): string {
    return this.formattedDate(date) + "  " + this.app.timeZone;
  }

  public formattedDate(date: string): string {
    const dueDate = moment(date).tz(this.timeZoneId).format(DATE_FORMAT);
    const midnight = "12:00am";
    return dueDate.includes(midnight)
      ? "midnight, " +
          moment(date)
            .tz(this.timeZoneId)
            .subtract(1, "days")
            .format("DD MMM YYYY")
      : moment(date).tz(this.timeZoneId).format(DATE_FORMAT);
  }

  public get isOverdueOrIsOutsideVariationStage(): boolean {
    return (
      this.overdue(this.app.subsequentAppStatus.respDueDate) ||
      this.outsideVariationStage
    );
  }

  public get outsideVariationStage(): boolean {
    return this.app.subsequentAppStatus &&
      this.app.subsequentAppStatus.respIsVariationNotInVariationStage
      ? this.app.subsequentAppStatus.respIsVariationNotInVariationStage
      : false;
  }

  public get hideSubsequentResponseDueDate(): boolean {
    return (
      this.outsideVariationStage &&
      (!this.app.subsequentAppStatus.respDueDate ||
        this.app.subsequentAppStatus.respDueDate === "")
    );
  }

  public get archived(): boolean {
    return this.app.archived;
  }

  public get ownedName(): string {
    return (
      this.app.userName + (this.app.userId === this.user.id ? " (me)" : "")
    );
  }

  public get owned(): string {
    return (
      "Owned by " +
      this.ownedName +
      (this.app.orgOwner ? " for " + this.app.orgOwner : "")
    );
  }

  public get ownedFormatted(): string {
    let str = "Owned by <strong>";
    if (this.isOwnedByHighlighted) {
      str += "<mark>" + this.ownedName + "</mark>";
    } else {
      str += this.ownedName;
    }
    return str + "</strong>";
  }

  public get roundHighlighted(): string {
    if (this.isRoundHighlighted) {
      return "<mark>" + this.round + "</mark>";
    } else {
      return this.round;
    }
  }

  public get isOwnedByHighlighted(): boolean {
    const hl = this.getSearchHighlight;
    return hl.length > 0 && hl.find(k => k.key === "app-user-name") != null;
  }

  public get isRoundHighlighted(): boolean {
    const hl = this.getSearchHighlight;
    return hl.length > 0 && hl.find(k => k.key === "app-round-name") != null;
  }

  public get orgOwner(): string {
    return this.app.orgOwner;
  }

  public get userPermission(): UserPermissionDto {
    return this.app.userPermission;
  }

  public get status(): string {
    return this.app.status;
  }

  public get id(): number {
    return this.app.applicationId;
  }

  public get round(): string {
    return this.app.round;
  }

  public get isVisible(): boolean {
    return this.visible;
  }

  public get header(): string {
    return (
      this.app.application +
      (this.app.projectTitle ? " - " + this.app.projectTitle : "")
    );
  }

  public get timeZoneId(): string {
    return this.timeZone.id ? this.timeZone.id : DEFAULT_TIME_ZONE_ID;
  }

  public get statusDescription(): string {
    let description: string;
    if (this.app.status === "SUBMITTED") {
      description = "Submitted";
      if (this.app.subsequentAppStatus) {
        if (this.app.subsequentAppStatus.respStatus === "IN_PROGRESS") {
          description += ", next form in progress";
        } else if (this.app.subsequentAppStatus.respStatus === "NOT_STARTED") {
          description += ", next form available";
        } else if (this.app.subsequentAppStatus.respStatus === "INCOMPLETE") {
          description += ", next form incomplete";
        }
      }
    } else if (this.app.status === "INCOMPLETE") {
      description = "Incomplete";
    } else {
      description = "In progress";
    }
    return description;
  }

  public get subsequentStatus(): string {
    let status: string;
    if (this.app.status === "SUBMITTED") {
      status = "SUBMITTED";
      if (this.app.subsequentAppStatus) {
        if (this.app.subsequentAppStatus.respStatus === "IN_PROGRESS") {
          status = "IN_PROGRESS";
        } else if (this.app.subsequentAppStatus.respStatus === "NOT_STARTED") {
          status = "NOT_STARTED";
        } else if (this.app.subsequentAppStatus.respStatus === "INCOMPLETE") {
          status = "INCOMPLETE";
        }
      }
    } else if (this.app.status === "INCOMPLETE") {
      status = "INCOMPLETE";
    } else {
      status = "IN_PROGRESS";
    }
    return status;
  }

  public get amountRequested(): string {
    if (this.app.totalAmountRequested) {
      return (
        "Total amount requested: " +
        this.app.totalAmountRequested.formattedAmount
      );
    } else {
      return "";
    }
  }

  public get timeZone(): TimeZoneDto {
    return this.app.timeZone;
  }

  public get timeZoneShortName(): string {
    const shortName: string = this.timeZone.shortName;
    if (this.isSubmitted) {
      if (this.app.subsequentAppStatus) {
        if (this.app.subsequentAppStatus.respStatus) {
          if (this.app.subsequentAppStatus.respStatus === "SUBMITTED") {
            return "(" + shortName + ")";
          } else if (this.app.subsequentAppStatus.respStatus === "INCOMPLETE") {
            if (this.app.subsequentAppStatus.respDueDate) {
              return (
                "<b " +
                (this.isOverdueOrIsOutsideVariationStage
                  ? "style='color:lightgrey'"
                  : "") +
                ">(" +
                shortName +
                ")" +
                "</b>"
              );
            } else if (this.outsideVariationStage) {
              return "";
            }
          } else if (
            this.app.subsequentAppStatus.respStatus === "IN_PROGRESS" ||
            this.app.subsequentAppStatus.respStatus === "NOT_STARTED"
          ) {
            if (this.app.subsequentAppStatus.respDueDate) {
              return (
                "<b " +
                (this.dueInaWeek ? "style='color:rgb(200,0,0)'" : "") +
                ">(" +
                shortName +
                ")" +
                "</b>"
              );
            } else {
              return "";
            }
          }
        }
      }
      return "(" + shortName + ")";
    } else if (this.app.dueDate) {
      return (
        "<b " +
        (this.overdue(this.app.dueDate) ? "style='color:lightgrey'" : "") +
        ">(" +
        shortName +
        ")" +
        "</b>"
      );
    } else {
      return "";
    }
  }

  public get getTotalAmountRequested(): CurrencyDto {
    return this.app.totalAmountRequested;
  }

  public get isRemoved(): boolean {
    return this.removed;
  }

  public get getSearchHighlight(): Array<SearchHighlightDto> {
    return this.app.highlight ? this.app.highlight : [];
  }

  public get getSearchHighlightText(): string {
    let str = "<i>";
    const formName = this.app.highlight.find(
      hl => hl.key === "app-resp-ext-form-name"
    );
    if (formName != null && formName.value.length > 0) {
      str += "Form name: " + formName.value[0] + "<br>";
    }

    const responseText = this.app.highlight.find(
      hl => hl.key === "app-ext-response-text"
    );
    if (responseText != null && responseText.value.length > 0) {
      str += "Response text: " + responseText.value[0];
    }

    const sharedUser = this.app.highlight.find(
      hl => hl.key === "app-shared-user-name"
    );
    if (sharedUser != null && sharedUser.value.length > 0) {
      str += "Shared with: " + sharedUser.value[0];
    }

    str += "</i>";

    return str;
  }

  public get getErrorMessage(): string {
    return this.errorMessage ? this.errorMessage.message : null;
  }

  public get getErrorMessageTitle(): string {
    return this.errorMessage ? this.errorMessage.title : null;
  }

  public search(text: string): boolean {
    const lowerSearchText = text.toLowerCase();
    this.visible =
      !text ||
      text.length === 0 ||
      (!!this.app.projectTitle &&
        this.app.projectTitle.toLowerCase().includes(lowerSearchText)) ||
      (!!this.app.round &&
        this.app.round.toLowerCase().includes(lowerSearchText)) ||
      (!!this.app.application &&
        this.app.application.toLowerCase().includes(lowerSearchText)) ||
      this.amountRequested.toLowerCase().includes(lowerSearchText) ||
      (this.getTotalAmountRequested &&
        this.getTotalAmountRequested.amount
          .toString()
          .includes(lowerSearchText)) ||
      (this.getTotalAmountRequested &&
        currencyFilter(
          this.getTotalAmountRequested.amount,
          this.getTotalAmountRequested.symbol
        ).includes(lowerSearchText)) ||
      this.statusDate.toLowerCase().includes(lowerSearchText) ||
      this.owned.toLowerCase().includes(lowerSearchText) ||
      this.statusDescription.toLowerCase().includes(lowerSearchText);
    return this.visible;
  }

  public remove(): void {
    this.removed = true;
  }

  public clearError(): void {
    this.errorMessage = null;
  }

  public get isShared(): boolean {
    return this.app.shared;
  }

  public get isExpanded(): boolean {
    return this.expanded;
  }

  public toggleExpanded(flag: boolean): void {
    this.expanded = flag;
  }

  public get instanceOwner(): string {
    if (this.app.instanceOwner) {
      return this.app.instanceOwner;
    } else {
      return "";
    }
  }

  public get instanceLogoUrl(): string {
    return this.app.instanceLogoUrl;
  }
}
